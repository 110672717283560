<template>
  <div id="app">
    <div class="logo">
      <img src="../../public/imgs/logo.png" alt="">
    </div>
    <div class="content">
      <div class="input-item">
        <div class="input-span">
          用户名
        </div>
        <div class="input-text">
          <input class="input" v-model="userCode" placeholder="请输入账号" />
        </div>
      </div>
      <div class="input-item">
        <div class="input-span">
          密码
        </div>
        <div class="input-text">
          <input class="input" type="password" v-model="passWord" placeholder="请输入密码" />
        </div>
      </div>
      <div class="input-item btn">
        <button @click="login">登录</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {
      userCode: "",
      passWord: ""
    }
  },
  metaInfo() {
    return {
      title: '登录'
    }
  },

  methods: {
    login() {
      // 获取用户输入的账号和密码
      var userCode = this.userCode;
      var passWord = this.passWord;
      // 验证用户名和密码是否为空
      if (!userCode && !passWord) {
        alert('请输入用户名和密码');
        return; // 如果用户名和密码都未填写，则终止方法执行
      } else if (!userCode) {
        alert('请输入用户名');
        return; // 如果用户名未填写，则终止方法执行
      } else if (!passWord) {
        alert('请输入密码');
        return; // 如果密码未填写，则终止方法执行
      }

      // 创建一个 FormData 对象
      var formData = new FormData();
      formData.append('userCode', userCode);
      formData.append('passWord', passWord);

      fetch('https://kexin.oa.oldkb.top/index.php?s=/ScanCode/userLogin', {
        method: 'POST',
        // credentials: 'include',
        headers: {
          // 不需要设置 Content-Type，FormData 类型会自动设置
        },
        body: formData,
      })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error(`Request failed with status ${response.status}`);
          }
        })
        .then(data => {
          if (data.code === 200) {
            // 登录成功，跳转到其他页面
            this.$router.push('/search');
          } else if (data.code === 147) {
            // 密码错误
            alert('密码错误，请重新输入');
          } else {
            // 其他错误
            alert('用户不存在');
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }
}
</script>

<style scoped>
#app {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.logo {
  width: 100%;
  /* height: 58%; */
  display: flex;
  /* align-items: flex-end; */
  justify-content: center;
  /* margin-top: 50px; */
}

.logo img {
  width: 55%;
  padding-bottom: 40px;
  object-fit: contain;
}

.content {
  width: 100%;
  height: 42%;
}

.input-item {
  width: 65%;
  margin: auto;
  padding: 5px 0px;
  font-size: 18px;
}

.input-span {
  width: 100%;
  padding: 5px 0px;
}

.input-text {
  width: 100%;
}

.input {
  padding: 14px 12px;
  width: calc(100% - 26px);
  border: 1px solid #dcdddd;
  border-radius: 10px;
  font-size: 14px;
}

.input:focus-visible {
  outline: #dcdddd;
}

.btn {
  text-align: center;
}

.btn button {
  width: 100px;
  height: 40px;
  background-color: rgb(29, 115, 165);
  color: white;
  font-size: 18px;
  border: none;
  margin-top: 10px;
}
</style>